.landpageChild {
  position: relative;
  border-radius: var(--br-31xl);
  background: linear-gradient(
    139.52deg,
    rgba(22, 16, 55, 0.21),
    rgba(99, 95, 121, 0.36)
  );
  backdrop-filter: blur(13px) brightness(86%);
  border: 1px solid var(--color-dimgray-200);
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

.welcome {
  margin: 0;
  position: relative;
  z-index: 1;
}

.welcomeAdventurer {
  margin: 0;
  position: absolute;
  top: 20vh;
  left: 10vw;
  font-size: 5vw;
  font-weight: 400;
  font-family: inherit;
}

.superYouAre {
  color: var(--color-khaki);
}

.alrightLetsEmbarkContainer {
  position: absolute;
  top: 324px;
  left: 10vw;
  font-size: 25px; /* Reduce font size from 28px to 18px */
  font-family: var(--font-trispace-text);
  display: inline-block;
  width: 731px;
  height: 288px;
  text-decoration: none;
  opacity: 1;
  color: inherit;
  z-index: 1;
}

.landpageItem {
  width: 343px;
  height: auto;
  position: absolute;
  bottom: 35px;
  left: 131px;
  cursor: pointer;
  z-index: 2;
  background-color: white;
  color: black;
  border: 1px solid black;
}
.landpageItem:hover {
  background-color: #F3C96A;
  border: 1px solid black;
  }

.sustainabilityqIcon {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the element horizontally and vertically */
  width: 400px; /* Replace 300px with the desired width */
  height: auto; /* Automatically adjust the height based on the aspect ratio */
  object-fit: cover;
  z-index: 3;
}

.studioPc232846ConvertedIcon {
  position: absolute;
  right: 20px;
  bottom: 14px;
  width: 534px;
  height: 569px;
  object-fit: cover;
  z-index: 4; /* Set higher z-index than landpageItem */
}

.landpage {
  position: relative;
  background-color: var(--color-darkslateblue);
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  text-align: left;
  font-size: 100px;
  color: var(--color-white);
  font-family: var(--font-space-odyssey);
  font-weight: 400;
}
