.sustainabilityqIcon {
  position: absolute;
  top: 3%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the element horizontally and vertically */
  width: 340px; /* Replace 300px with the desired width */
  height: auto; /* Automatically adjust the height based on the aspect ratio */
  object-fit: cover;
  z-index: 10;
}
.allCharacters {
  position: relative;
  background-color: var(--color-darkslateblue);
  width: 100%;
  text-align: left;
  font-size: 22px;
  color: var(--color-khaki);
  font-family: var(--font-space-odyssey);
  padding-top: 80px;
}

.allCharactersButton {
  position: absolute;
  top: 2%;
  right: 5%;
  width: 100px;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
  z-index: 10;
}
.allCharactersButton:hover {
background-color: #F3C96A;
border: 1px solid black;
}
.allCharactersButton:hover {
  filter: drop-shadow(0px 0px 10px white);
}

.allCharactersList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 80%; /* Set maximum width to 80% of the available width */
  margin: 80px auto 0;
}

.character {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin: 20px 0;
}

.characterImage {
  width: 200px; /* Adjust as needed */
  height: 200px; /* Adjust as needed */
  object-fit: cover;
  margin-right: 20px;
}

.characterName {
  margin: 0;
  font-size: inherit;
  font-weight: 500;
  font-family: inherit;
  -webkit-text-stroke: 1px #f3c96a;
  line-height: 3;
}

.characterDescription {
  margin: 0;
  color: var(--color-white);
  line-height: 1.5;
}
