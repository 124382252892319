.game8Child {
    position: relative;
    border-radius: var(--br-31xl);
    background: linear-gradient(
      139.52deg,
      rgba(22, 16, 55, 0.21),
      rgba(99, 95, 121, 0.36)
    );
    backdrop-filter: blur(13px) brightness(86%);
    border: 1px solid var(--color-dimgray-200);
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
  }
  .chapter1,
  .groceryGrab {
    margin: 0;
    position: absolute;
    top: 10vh;
    left: 5vw;
    font-size: 5vw;
    font-weight: 400;
    font-family: inherit;
  }
  .chapter1 {
    top: 15vh;
    left: 5vw;
    font-size: var(--font-size-21xl);
  }
  .groceryGrab {
    top: 26vh;
    left: 5vw;
    font-size: var(--font-size-61xl);
    color: var(--color-khaki);
    -webkit-text-stroke: 1px #f3c96a;
  }
  .youStepInto {
    margin: 0;
  }
  .itsMrPlastic {
    color: var(--color-khaki);
  }
  .aSeasonedAdventurer {
    color: var(--color-white);
  }
  .youStepIntoContainer {
    position: absolute;
    top: 43vh;
    left: 5vw;
    font-size: 20px; /* Reduce font size from 28px to 18px */
    font-family: var(--font-trispace-text);
    display: inline-block;
    width: 600px;
    height: 288px;
    text-decoration: none;
    opacity: 1;
    color: inherit;
    z-index: 1;
    line-height: 1.7;
  }
  .sustainabilityqIcon {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the element horizontally and vertically */
    width: 340px; /* Replace 300px with the desired width */
    height: auto; /* Automatically adjust the height based on the aspect ratio */
    object-fit: cover;
    z-index: 3;
  }
  .game8Item {
    position: absolute;
    top: 3%;
    right: 5%;
    width: 100px;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 1px solid black;
  }
  .game8Item:hover {
    background-color: #F3C96A;
    border: 1px solid black;
  }
  
  .groupChild {
    position: absolute;
    width: calc(100% - 32px);
    top: 9px;
    right: 0;
    left: 32px;
    border-radius: var(--br-4xl);
    background-color: var(--color-linen);
    border: 5px solid var(--color-seagreen);
    box-sizing: border-box;
    height: 103px;
  }
  .sayHiToContainer {
    position: absolute;
    width: calc(100% - 64px);
    top: calc(45% - 32.5px);
    left: 65.5px;
    font-size: var(--font-size-xl);
    font-family: var(--font-myanmar-text);
    color: var(--color-black);
    text-align: center;
    display: inline-block;
    height: 50px;
    -webkit-text-stroke: 1px #6a6a6a;
    transform: scale(0.8); /* Scale the button down by 20% */
  }
  .sayHiToContainer:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .leaf1Icon {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    max-height: 100%;
    width: 115px;
    object-fit: cover;
  }
  .leaf1Icon:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .rectangleParent {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    bottom: 25vh;
    left: 2.5vw;
    width: 363px;
    height: 136px;
    transform: scale(0.8); /* Scale the button down by 20% */
  }
  .rectangleParent:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .groupItem {
    position: absolute;
    top: 9px;
    left: 0;
    border-radius: var(--br-lg);
    background-color: var(--color-linen);
    border: 5px solid var(--color-seagreen);
    box-sizing: border-box;
    width: 331px;
    height: 103px;
  }
  .highFiveMrsReusable {
    position: absolute;
    transform: scale(0.8);
    width: calc(100% - 132px);
    top: calc(45% - 43.5px);
    left: 33.5px;
    font-size: var(--font-size-xl);
    font-family: var(--font-myanmar-text);
    color: var(--color-black);
    text-align: center;
    display: inline-block;
    height: 71px;
    -webkit-text-stroke: 1px #727272;
  }
  .highFiveMrsReusableIcon:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .leaf2Icon {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    max-height: 100%;
    width: 115px;
    object-fit: cover;
  }
  .leaf2Icon:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .rectangleGroup {
    display: flex;
    justify-content: space-between;
    transform: scale(0.8);
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    bottom: 45vh;
    left: 25vw;
    width: 363px;
    height: 136px;
    z-index: 2;
  }
  .rectangleGroup:hover {
    filter: drop-shadow(0px 0px 10px white);
  }
  .game8Inner {
    position: absolute;
    top: 23vh;
    right: 16px;
    box-sizing: border-box;
    width: 719px;
    height: 477px;
  }
  .game8Inner video {
    max-width: 100%; 
    max-height: 100%; 
    z-index: 10;
  }
  .game8 {
    position: relative;
    background-color: var(--color-darkslateblue);
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    text-align: left;
    font-size: var(--font-size-61xl);
    color: var(--color-white);
    font-family: var(--font-space-odyssey);
  }
  