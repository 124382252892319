/* Common styles */
.gamefinalChild {
  position: relative;
  border-radius: var(--br-31xl);
  background: linear-gradient(
    139.52deg,
    rgba(22, 16, 55, 0.21),
    rgba(99, 95, 121, 0.36)
  );
  backdrop-filter: blur(13px) brightness(86%);
  border: 1px solid var(--color-dimgray-200);
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

/* Typography styles */
.yourScore,
.characterName {
  margin: 0;
  position: absolute;
  top: 10vh;
  left: 5vw;
  font-weight: 400;
  font-family: inherit;
}

.yourScore {
  font-size: var(--font-size-21xl);
  top: 15vh;
}

.characterName {
  font-size: var(--font-size-61xl);
  color: var(--color-khaki);
  -webkit-text-stroke: 1px #f3c96a;
  top: 26vh;
}

.characterDescription,
.blankLine {
  margin: 0;
}

.characterDescriptionSomeMoContainer,
.sustainabilityqIcon {
  position: absolute;
  top: 43vh;
  left: 5vw;
  font-size: 18px;
  font-family: var(--font-trispace-text);
  display: inline-block;
  width: 631px;
  height: 88px;
  text-decoration: none;
  opacity: 1;
  color: inherit;
  z-index: 1;
}

.sustainabilityqIcon {
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  height: auto;
  object-fit: cover;
  z-index: 3;
}

/* Game items */
.gamefinalItem,
.groupChild,
.back,
.leaf1Icon,
.leaf2Icon,
.rectangleParent,
.groupItem,
.readMore,
.totalscore,
.gamefinalInner,
.rectangleGroup {
  position: absolute;
  box-sizing: border-box;
  transform: scale(0.8);
}

.gamefinalItem {
  position: absolute;
  top: 15vh;
  right: 10vw;
  width: 550px;
  height: 550px;
  cursor: pointer;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gamefinalItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.groupChild {
  width: calc(100% - 32px);
  top: 9px;
  right: 0;
  left: 32px;
  border-radius: var(--br-4xl);
  background-color: var(--color-linen);
  border: 5px solid var(--color-seagreen);
  height: 103px;
}

.back {
  width: calc(100% - 64px);
  top: 34%;
  left: 55%;
  transform: translate(-50%, -50%) ; /* Center horizontally and vertically */
  font-size: 30px;
  font-family: 'Open Sans', sans-serif; /* Use Open Sans font family */
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  height: 50px;
  -webkit-text-stroke: 1px #6a6a6a;
  cursor: pointer;
}

.leaf1Icon,
.leaf2Icon {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
}

.leaf1Icon {
  top: -17%;
  left: 0;
}

.leaf2Icon {
  top: -17%;
  right: -7%;
}

.rectangleParent,
.rectangleGroup {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 350px;
  height: 180px;
  bottom: 0vh;
}

.rectangleParent {
  left: 2.5vw;
}

.rectangleGroup {
  left: 30vw;
}

.groupItem {
  top: 9px;
  left: 0;
  border-radius: var(--br-lg);
  background-color: var(--color-linen);
  border: 5px solid var(--color-seagreen);
  width: 331px;
  height: 103px;
}

.readMore {
  transform: scale(0.8);
  width: calc(100% - 132px);
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  font-size: 30px;
  font-family: 'Open Sans', sans-serif; /* Use Open Sans font family */
  color: var(--color-black);
  text-align: center;
  display: inline-block;
  height: 71px;
  -webkit-text-stroke: 1px #727272;
  cursor: pointer;
}

.rectangleGroup:hover,
.rectangleParent:hover,
.back:hover,
.leaf1Icon:hover,
.leaf2Icon:hover,
.readMore:hover {
  filter: drop-shadow(0px 0px 10px white);
}

.totalscore {
  top: 60vh;
  left: 1vw;
  font-size: 25px;
  font-family: var(--font-trispace-text);
  display: inline-block;
  width: 631px;
  height: 88px;
  text-decoration: none;
  font-weight: 1px;
  opacity: 1;
  color: inherit;
  z-index: 1;
}

.gamefinalInner {
  top: 23vh;
  right: 21px;
  background-color: var(--color-gray);
  border: 1px solid var(--color-dimgray-100);
  width: 719px;
  height: 477px;
}

.gamefinal {
  position: relative;
  background-color: var(--color-darkslateblue);
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  text-align: left;
  font-size: var(--font-size-61xl);
  color: var(--color-white);
  font-family: var(--font-space-odyssey);
}
