@import url("https://fonts.googleapis.com/css2?family=Trispace&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-trispace-text: "Trispace Text";
  --font-open-sans: "Open Sans";

  /* font sizes */
  --font-size-xl: 20px;
  --font-size-6xl: 25px;
  --font-size-71xl: 90px;
  --font-size-61xl: 80px;

  /* Colors */
  --color-darkslateblue: rgba(30, 37, 75, 0.75);
  --color-khaki: #f3c96a;
  --color-white: #fff;
  --color-dimgray-100: #707070;
  --color-dimgray-200: rgba(112, 112, 112, 0.37);
  --color-gray: #070606;
  --color-black: #000;
  --color-linen: #f4eddf;
  --color-seagreen: #307237;

  /* Border radiuses */
  --br-31xl: 50px;
  --br-lg: 18px;
  --br-4xl: 23px;
}
